.header {
  /* border: 1px solid red; */
  height: 80px;
  border-bottom: 2px solid #d9d9d9;
  /* display: flex; */
  display: flex;
  justify-content: end;
  align-items: center;
}
.header-items{
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 30px;
}
.greet{
  text-align: right;
  padding: 10px 30px;
}
.greet h1{
  font-size: 25px;
  margin: 0;
  padding: 0;
}
.user-icon{
  height: 50px;
  width: 50px;
  background: #d9d9d9;
  border-radius: 50%;
}
.body1{
  /* border: 1px solid blue; */
  height: 780px;
  padding: 0;
  display: flex;
}
.leftNav {
  height: 100%;
  width: 20%;
  padding: 0;
  /* border: 1px solid green; */
  background: #f0f0f0;
}
.content{
  height: 100%;
  width: 80%;
  /* border: 1px solid black; */
}
.logo-img {
  height: 40px;
  width: 150px;
  margin: 21px 15px -12px 40px;
}
.division{
  background: #000000;
  height: 1px;
  margin: 30px 0;
}
.btn-container{
  padding: 0 20px 0 0;
  display: grid;
  grid-template-rows: 1fr auto;
}
.top-btn{
  padding: 0;
}
.btm-btn{
  padding: 0;
  /* margin-top: auto; */
}
.dash-btn{
  display: flex;
  height: 50px;
  color: #580eac;
  border-radius: 0px 10px 10px 0
}
.dash-btn:hover{
  background: #c9aaeb;
  color: #fff;
}
.active-btn{
  background: #580eac;
  color: #fff;
}
.icon{
  height: 100%;
  width: 15%;
  padding: 10px;
}
.btn-name{
  height: 100%;
  padding: 10px;
  font-size: 18px;
}